import { IAccessToken, IUserTokenMinimal, IUserTokenRequest } from "@/interfaces/auth/token";
import { IContainer, IContainerSearch } from "@/interfaces/container";
import { IMachine, IMachineContainerLinkSubmit, IMachineUpdate } from "@/interfaces/machine";
import {
  IUser,
  IUserSettings,
  IUserUpdate,
  IUserUpdateBio,
  IUserUpdatePassword,
  IUserUpdateUsername
} from "@/interfaces/user";

import { IAllScopes } from "@/interfaces/role";
import { apiUrl } from "@/consts";
import axios from "axios";

export const meAPI = {
  async deleteMe() {
    return axios.delete<void>(`${apiUrl}/v1/user/me`,);
  },

  async getMe() {
    return axios.get<IUser>(`${apiUrl}/v1/user/me`,);
  },

  async getMeContainers(searchQuery: IContainerSearch) {
    const params = {
      limit: 0,
      ...searchQuery,
    }

    return axios.get<IContainer[]>(
      `${apiUrl}/v1/user/me/containers`,
      {
        params: params,
      }
    );
  },

  async getMeOwnedContainers(offset = 0, limit = 100) {
    const params = {
      offset: offset,
      limit: limit,
    }

    return axios.get<IContainer[]>(
      `${apiUrl}/v1/user/me/containers/self`,
      {
        params: params,
      }
    );
  },

  async getMeContainersStats() {
    return axios.head<void>(`${apiUrl}/v1/user/me/containers/self`)
  },

  async getMeContributedContainers() {
    return axios.get<IContainer[]>(
      `${apiUrl}/v1/user/me/containers/contributed`,
    );
  },

  async updateMe(user: IUserUpdate) {
    return axios.patch<IUser>(
      `${apiUrl}/v1/user/me`,
      user,
    );
  },

  async updateMePassword(userPassword: IUserUpdatePassword) {
    return axios.put<IUser>(
      `${apiUrl}/v1/user/me/password`,
      userPassword,
    );
  },

  async updateMeUsername(userUsername: IUserUpdateUsername) {
    return axios.put<IUser>(
      `${apiUrl}/v1/user/me/username`,
      userUsername,
    );
  },

  async updateMeBio(bio: IUserUpdateBio) {
    return axios.put<IUser>(
      `${apiUrl}/v1/user/me/bio`,
      bio,
    );
  },

  async updateMeSettings(settings: IUserSettings) {
    return axios.patch<IUserSettings>(`${apiUrl}/v1/user/me/settings`, settings,)
  },

  async generateMeIdentityCode() {
    return axios.put<IUser>(
      `${apiUrl}/v1/user/me/identity-code`,
      {},
    );
  },

  async getMeTokens() {
    return axios.get<IUserTokenMinimal[]>(
      `${apiUrl}/v1/user/me/tokens`,
    );
  },

  async revokeMeToken(userTokenId: string) {
    return axios.put<IUserTokenMinimal>(
      `${apiUrl}/v1/user/me/tokens/${userTokenId}/revoke`,
      {},
    );
  },

  async deleteMeToken(userTokenId: string) {
    return axios.delete<void>(
      `${apiUrl}/v1/user/me/tokens/${userTokenId}`,
    );
  },

  async createMeToken(userTokenRequest: IUserTokenRequest) {
    return axios.post<IAccessToken>(
      `${apiUrl}/v1/user/me/tokens`,
      userTokenRequest,
    );
  },


  async getMeScopesDescription() {
    return axios.get<IAllScopes>(`${apiUrl}/v1/user/me/scopes/description`,)
  },

  async getMeMachines(offset = 0, limit = 100) {
    return axios.get<IMachine[]>(`${apiUrl}/v1/user/me/machines?offset=${offset}&limit=${limit}`)
  },

  async updateMeMachineMachinename(machineId: string, machineMachineName: IMachineUpdate) {
    return axios.put<IMachine>(
      `${apiUrl}/v1/user/me/machines/${machineId}/machinename`,
      machineMachineName,
    );
  },

  async updateMeMachineTestOwnerContainer(machineId: string, machineTestOwnerContainer: IMachineUpdate) {
    return axios.put<IMachine>(
      `${apiUrl}/v1/user/me/machines/${machineId}/test-owner-container`,
      machineTestOwnerContainer,
    );
  },

  async getMeMachineContainers(machineId: string, offset = 0, limit = 100) {
    return axios.get<IContainer[]>(`${apiUrl}/v1/user/me/machines/${machineId}/containers`, {
      params: {
        offset: offset,
        limit: limit,
      },
    }
    )
  },

  async associateMeMachineToContainer(machineId: string, containers: IMachineContainerLinkSubmit) {
    return axios.post<void>(
      `${apiUrl}/v1/user/me/machines/${machineId}/containers`,
      containers,
    );
  },

  async getMeMachinesStats() {
    return axios.head<void>(`${apiUrl}/v1/user/me/machines`)
  },

  async deleteMeMachine(machineId: string) {
    return axios.delete<void>(`${apiUrl}/v1/user/me/machines/${machineId}`)
  },

  async generateMeMachine() {
    return axios.post<IMachine>(`${apiUrl}/v1/user/me/machines`)
  },

  async generateMeMachineToken(machineId: string) {
    return axios.post<IAccessToken>(`${apiUrl}/v1/user/me/machines/${machineId}/tokens`)
  },

  async closeMeMachineTestingScope(machineId: string) {
    return axios.put<IMachine>(`${apiUrl}/v1/user/me/machines/${machineId}/close-scope`, {});
  },

  async openMeMachineTestingScope(machineId: string) {
    return axios.put<IMachine>(`${apiUrl}/v1/user/me/machines/${machineId}/open-scope`, {});
  },

};


export default meAPI
