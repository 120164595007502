import { IContainer, IContainerCreate, IContainerExportRule, IContainerExportRuleCreate, IContainerExportRuleUpdate, IContainerRemoteSubject, IContainerRemoteSubjectCreate, IContainerRemoteSubjectUpdate, IContainerSearch, IContainerSubject, IContainerUpdate } from "@/interfaces/container";
import { ISubjectSearch, ISubjectSubmit } from "@/interfaces/aggregation/subject";
import { commitAddLContainerAssociatedSubject, commitAddLContainerExportRule, commitAddLContainerIgnoredSubject, commitAddLContainerStashedSubject, commitRemoveContainer, commitRemoveLContainerAssociatedSubject, commitRemoveLContainerIgnoredSubject, commitRemoveLContainerRemoteSubject, commitRemoveLContainerStashedSubject, commitSetContainerContributors, commitSetContainerViewers, commitSetContainers, commitSetContainersTotal, commitSetLContainer, commitSetLContainerAssociatedSubjects, commitSetLContainerAssociatedSubjectsTotal, commitSetLContainerExportRules, commitSetLContainerExportRulesTotal, commitSetLContainerIgnoredSubjects, commitSetLContainerIgnoredSubjectsTotal, commitSetLContainerRemoteSubjects, commitSetLContainerRemoteSubjectsTotal, commitSetLContainerStashedSubjects, commitSetLContainerStashedSubjectsTotal, commitSetLContainerSubjectLockedLink } from "@/store/container/mutations";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";

import { ActionContext } from "vuex";
import { ContainerState } from "@/store/container/state";
import { IUser } from "@/interfaces/user";
import { State } from "@/store/state";
import api from "@/api";
import { commitRemoveLContainerExportRule } from "@/store/container/mutations";
import { dispatchCheckAPIError } from "@/store/main/actions";
import downloadBlob from "@/utils/download";
import { getStoreAccessors } from "typesafe-vuex";

type ContainerContext = ActionContext<ContainerState, State>;

export const actions = {
  async actionGetContainers(
    context: ContainerContext, { offset = 0, limit = 100 }) {
    try {
      const response = await api.getContainers(
        offset,
        limit
      );

      if (response.data) {
        commitSetContainers(context, response.data);
        commitSetContainersTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetContainers(context, []);
        commitSetContainersTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGetContainer(
    context: ContainerContext, info: { owner: string, containername: string }) {
    try {
      const response = await api.getContainer(
        info.owner,
        info.containername
      );

      if (response.data) {
        commitSetLContainer(context, response.data);
        return response.data
      } else {
        commitSetLContainer(context, null);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return null
  },

  async actionAddContainer(
    context: ContainerContext,
    container: IContainerCreate
  ) {
    const loadingNotification = {
      content: "Creating container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.addContainer(container);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully created the container.`,
        color: "success",
      });
      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }

    return null;
  },

  async actionUpdateContainer(
    context: ContainerContext,
    info: { container: IContainer; updated: IContainerUpdate }
  ) {
    const loadingNotification = {
      content: "Updating container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateContainer(
        info.container.owner.username,
        info.container.name,
        info.updated
      );

      if (context.rootState.container.lContainer?.id === response.data.id) {
        commitSetLContainer(context, response.data)
      }

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully updated the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionDeleteContainer(
    context: ContainerContext,
    container: IContainer
  ) {
    const loadingNotification = {
      content: "Deleting container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await Promise.all([
        api.deleteContainer(container.owner.username, container.name),
        await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
      ])

      commitRemoveNotification(context, loadingNotification);
      await commitRemoveContainer(context, container);

      commitAddNotification(context, {
        content: `Successfully deleted the ${container.owner.username}/${container.name} container.`,
        color: "success",
      });

      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return false;
  },

  async actionOverrideContainerOwners(
    context: ContainerContext,
    info: { container: IContainer; owners: IUser[] }
  ) {
    const loadingNotification = {
      content: "Updating container owners...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    return null;
  },

  async actionOverrideContainerContributors(
    context: ContainerContext,
    info: { container: IContainer; contributors: IUser[] }
  ) {
    const loadingNotification = {
      content: "Updating container contributors...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = (
        await Promise.all([
          api.overrideContainerContributors(info.container.owner.username, info.container.name, info.contributors.map(x => x.id)),
          await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
        ])
      )[0];

      commitRemoveNotification(context, loadingNotification);
      await commitSetContainerContributors(context, { container: info.container, contributors: response.data })

      commitAddNotification(context, {
        content: `Successfully update the contributors of the ${info.container.owner.name}/${info.container.name} container.`,
        color: "success",
      });
      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null
  },

  async actionOverrideContainerViewers(
    context: ContainerContext,
    info: { container: IContainer; viewers: IUser[] }
  ) {
    const loadingNotification = {
      content: "Updating container viewers...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = (
        await Promise.all([
          api.overrideContainerViewers(info.container.owner.username, info.container.name, info.viewers.map(x => x.id)),
          await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
        ])
      )[0];

      commitRemoveNotification(context, loadingNotification);
      await commitSetContainerViewers(context, { container: info.container, viewers: response.data })

      commitAddNotification(context, {
        content: `Successfully update the viewers of the ${info.container.owner.name}/${info.container.name} container.`,
        color: "success",
      });
      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },


  async actionGetContainersTotal(context: ContainerContext) {
    try {
      const response = await api.getContainerStats(
      );

      if (response.status === 200) {
        commitSetContainersTotal(context, parseInt(response.headers["x-total"]));
      } else {
        commitSetContainersTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },


  async actionSearchContainer(context: ContainerContext, { searchQuery = "", approximate = false, offset = 0, limit = 100 }) {
    const query: IContainerSearch = { approximate: approximate }

    query.display_name = searchQuery
    query.name = searchQuery

    try {
      const response = await api.searchContainers(query, offset, limit);

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error)
    }
    return []
  },

  async actionSearchContainerSubjects(context: ContainerContext, info: { searchQuery: string, container: IContainer, approximate: boolean, offset: number, limit: number }) {
    const query: ISubjectSearch = {
      approximate: info.approximate ? info.approximate : true,
      subject: info.searchQuery
    }

    try {
      const response = await api.searchContainerSubjects(info.container.owner.username, info.container.name, query, info.offset ? info.offset : 0, info.limit ? info.limit : 100);

      if (response.data) {
        commitSetLContainerAssociatedSubjects(context, response.data);
        commitSetLContainerAssociatedSubjectsTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)))
      } else {
        commitSetLContainerAssociatedSubjects(context, []);
        commitSetLContainerAssociatedSubjectsTotal(context, 0);
      }


      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error)
    }
    return []
  },

  async actionSearchContainerIgnoredSubjects(context: ContainerContext, info: { searchQuery: string, container: IContainer, approximate: boolean, offset: number, limit: number }) {
    const query: ISubjectSearch = {
      approximate: info.approximate ? info.approximate : true,
      subject: info.searchQuery
    }

    try {
      const response = await api.searchContainerIgnoredSubjects(info.container.owner.username, info.container.name, query, info.offset ? info.offset : 0, info.limit ? info.limit : 100);

      commitSetLContainerIgnoredSubjects(context, response.data);
      commitSetLContainerIgnoredSubjectsTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)))

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error)
    }
    return []
  },

  async actionSearchContainerStashedSubjects(context: ContainerContext, info: { searchQuery: string, container: IContainer, approximate: boolean, offset: number, limit: number }) {
    const query: ISubjectSearch = {
      approximate: info.approximate ? info.approximate : true,
      subject: info.searchQuery
    }

    try {
      const response = await api.searchContainerStashedSubjects(info.container.owner.username, info.container.name, query, info.offset ? info.offset : 0, info.limit ? info.limit : 100);

      commitSetLContainerStashedSubjects(context, response.data);
      commitSetLContainerStashedSubjectsTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)))

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error)
    }
    return []
  },

  async actionSearchAndPushContainers(context: ContainerContext, { searchQuery = "", approximate = false, offset = 0, limit = 100 }) {
    const query: IContainerSearch = { approximate: approximate }

    query.display_name = searchQuery
    query.name = searchQuery

    try {
      const response = await api.searchContainers(query, offset, limit);

      if (response.data) {
        commitSetContainers(context, response.data);
        commitSetContainersTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)))
      } else {
        commitSetContainers(context, []);
        commitSetContainersTotal(context, 0);
      }

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error)
    }
    return []
  },

  async actionAddContainerSubjects(
    context: ContainerContext,
    info: { container: IContainer; subjects: ISubjectSubmit }
  ) {
    const loadingNotification = {
      content: "Adding subjects into container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.addContainerSubjects(
        info.container.owner.username,
        info.container.name,
        info.subjects
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully added subjects into the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionAddSingleContainerSubject(
    context: ContainerContext,
    info: { container: IContainer; subject: IContainerSubject }
  ) {
    const loadingNotification = {
      content: "Adding subject into container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.addContainerSubject(
        info.container.owner.username,
        info.container.name,
        info.subject.id
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully added subject into the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionAddMultipleContainerSubject(
    context: ContainerContext,
    info: { container: IContainer; subjects: IContainerSubject[] }
  ) {
    const loadingNotification = {
      content: `Adding ${info.subjects.length} subject(s) into container...`,
      color: "info",
      showProgress: true,
    };
    let errors = 0;
    let successes = 0;

    commitAddNotification(context, loadingNotification);

    for (const subject of info.subjects) {
      try {
        await api.addContainerSubject(
          info.container.owner.username,
          info.container.name,
          subject.id
        );

        commitRemoveLContainerStashedSubject(context, subject);
        commitRemoveLContainerIgnoredSubject(context, subject);
        commitAddLContainerAssociatedSubject(context, subject);

        successes += 1;
      } catch (error) {
        errors += 1;
      }
    }

    let message = `Successfully added ${successes} subjects into the ${info.container.owner.username}/${info.container.name} container.`

    if (errors > 0) {
      message += `\n\nFailed to add ${errors} subject(s).`
    }

    commitRemoveNotification(context, loadingNotification);
    commitAddNotification(context, {
      content: message,
      color: errors === 0 ? "success" : "warning",
    });

    return successes > 0;
  },

  async actionStashContainerSubjects(
    context: ContainerContext,
    info: { container: IContainer; subjects: ISubjectSubmit }
  ) {
    const loadingNotification = {
      content: "Stashing subjects into container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.stashContainerSubjects(
        info.container.owner.username,
        info.container.name,
        info.subjects
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully stashed subjects into the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionStashSingleContainerSubject(
    context: ContainerContext,
    info: { container: IContainer; subject: IContainerSubject }
  ) {
    const loadingNotification = {
      content: "Stashing subject into container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.stashContainerSubject(
        info.container.owner.username,
        info.container.name,
        info.subject.id
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully stashed subject into the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionStashMultipleContainerSubject(
    context: ContainerContext,
    info: { container: IContainer; subjects: IContainerSubject[] }
  ) {
    const loadingNotification = {
      content: `Stashing ${info.subjects.length} subject(s) into container...`,
      color: "info",
      showProgress: true,
    };

    let errors = 0;
    let successes = 0;

    commitAddNotification(context, loadingNotification);

    for (const subject of info.subjects) {
      try {
        await api.stashContainerSubject(
          info.container.owner.username,
          info.container.name,
          subject.id
        );

        commitRemoveLContainerAssociatedSubject(context, subject);
        commitRemoveLContainerIgnoredSubject(context, subject);
        commitAddLContainerStashedSubject(context, subject);

        successes += 1;
      } catch (error) {
        errors += 1;
      }
    }

    commitRemoveNotification(context, loadingNotification);

    let message = `Successfully stashed ${successes} subjects into the ${info.container.owner.username}/${info.container.name} container.`

    if (errors > 0) {
      message += `\n\nFailed to stash ${errors} subject(s).`
    }

    commitAddNotification(context, {
      content: message,
      color: errors === 0 ? "success" : "warning",

    });

    return successes > 0;
  },

  async actionIgnoreContainerSubjects(
    context: ContainerContext,
    info: { container: IContainer; subjects: ISubjectSubmit }
  ) {
    const loadingNotification = {
      content: "Ignoring subjects ...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.ignoreContainerSubjects(
        info.container.owner.username,
        info.container.name,
        info.subjects
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully ignored subjects from the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },


  async actionIgnoreSingleContainerSubject(
    context: ContainerContext,
    info: { container: IContainer; subject: IContainerSubject }
  ) {
    const loadingNotification = {
      content: "Ignoring subject from container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.ignoreContainerSubject(
        info.container.owner.username,
        info.container.name,
        info.subject.id
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully ignored subject from the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return true
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionIgnoreMultipleContainerSubject(
    context: ContainerContext,
    info: { container: IContainer; subjects: IContainerSubject[] }
  ) {
    const loadingNotification = {
      content: `Ignoring ${info.subjects.length} subject(s) from container...`,
      color: "info",
      showProgress: true,
    };

    let errors = 0;
    let successes = 0;

    commitAddNotification(context, loadingNotification);

    for (const subject of info.subjects) {
      try {
        await api.ignoreContainerSubject(
          info.container.owner.username,
          info.container.name,
          subject.id
        );

        commitRemoveLContainerAssociatedSubject(context, subject)
        commitRemoveLContainerStashedSubject(context, subject)
        commitAddLContainerIgnoredSubject(context, subject)

        successes += 1;
      } catch (error) {
        errors += 1;
      }
    }

    let message = `Successfully ignored ${successes} subjects into the ${info.container.owner.username}/${info.container.name} container.`

    if (errors > 0) {
      message += `\n\nFailed to ignore ${errors} subject(s).`
    }

    commitRemoveNotification(context, loadingNotification);
    commitAddNotification(context, {
      content: message,
      color: errors === 0 ? "success" : "warning",
    });

    return successes > 0;
  },

  async actionLockSingleContainerSubjectLink(
    context: ContainerContext,
    info: { container: IContainer; subject: IContainerSubject }
  ) {
    const loadingNotification = {
      content: "Locking container link...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.lockContainerSubjectLink(
        info.container.owner.username,
        info.container.name,
        info.subject.id
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully locked the link between ${info.subject.subject} and the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      commitSetLContainerSubjectLockedLink(context, { subject: info.subject, locked: true })

      return true
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionLockMultipleContainerSubjectLink(
    context: ContainerContext,
    info: { container: IContainer; subjects: IContainerSubject[] }
  ) {
    const loadingNotification = {
      content: `Locking ${info.subjects.length} container(s) link(s)...`,
      color: "info",
      showProgress: true,
    };

    let errors = 0;
    let successes = 0;

    commitAddNotification(context, loadingNotification);

    for (const subject of info.subjects) {
      try {
        await api.lockContainerSubjectLink(
          info.container.owner.username,
          info.container.name,
          subject.id
        );

        commitSetLContainerSubjectLockedLink(context, { subject: subject, locked: true })

        successes += 1;
      } catch (error) {
        errors += 1;
      }
    }

    let message = `Successfully locked ${successes} subjects links from the ${info.container.owner.username}/${info.container.name} container.`

    if (errors > 0) {
      message += `\n\nFailed to lock ${errors} subject(s) link(s).`
    }

    commitRemoveNotification(context, loadingNotification);
    commitAddNotification(context, {
      content: message,
      color: errors === 0 ? "success" : "warning",
    });

    return successes > 0;
  },

  async actionUnlockSingleContainerSubjectLink(
    context: ContainerContext,
    info: { container: IContainer; subject: IContainerSubject }
  ) {
    const loadingNotification = {
      content: "Unlocking container link...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.unlockContainerSubjectLink(
        info.container.owner.username,
        info.container.name,
        info.subject.id
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully unlocked the link between ${info.subject.subject} and the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      commitSetLContainerSubjectLockedLink(context, { subject: info.subject, locked: false })

      return true
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionUnlockMultipleContainerSubjectLink(
    context: ContainerContext,
    info: { container: IContainer; subjects: IContainerSubject[] }
  ) {
    const loadingNotification = {
      content: `Unlocking ${info.subjects.length} container(s) link(s)...`,
      color: "info",
      showProgress: true,
    };

    let errors = 0;
    let successes = 0;

    commitAddNotification(context, loadingNotification);

    for (const subject of info.subjects) {
      try {
        await api.unlockContainerSubjectLink(
          info.container.owner.username,
          info.container.name,
          subject.id
        );

        commitSetLContainerSubjectLockedLink(context, { subject: subject, locked: false })

        successes += 1;
      } catch (error) {
        errors += 1;
      }
    }

    let message = `Successfully unlocked ${successes} subjects links from the ${info.container.owner.username}/${info.container.name} container.`

    if (errors > 0) {
      message += `\n\nFailed to unlock ${errors} subject(s) link(s).`
    }

    commitRemoveNotification(context, loadingNotification);
    commitAddNotification(context, {
      content: message,
      color: errors === 0 ? "success" : "warning",
    });

    return successes > 0;
  },

  async actionDeleteSingleContainerSubject(
    context: ContainerContext,
    info: { container: IContainer; subject: IContainerSubject }
  ) {
    const loadingNotification = {
      content: "Deleting subject from container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.deleteContainerSubject(
        info.container.owner.username,
        info.container.name,
        info.subject.id
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully deleted subject from the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionDeleteMultipleContainerSubject(
    context: ContainerContext,
    info: { container: IContainer; subjects: IContainerSubject[] }
  ) {
    const loadingNotification = {
      content: `Deleting ${info.subjects.length} subject(s) from container...`,
      color: "info",
      showProgress: true,
    };
    let errors = 0;
    let successes = 0;

    commitAddNotification(context, loadingNotification);

    for (const subject of info.subjects) {
      try {
        await api.deleteContainerSubject(
          info.container.owner.username,
          info.container.name,
          subject.id
        );

        commitRemoveLContainerAssociatedSubject(context, subject)
        commitRemoveLContainerIgnoredSubject(context, subject)
        commitRemoveLContainerStashedSubject(context, subject)

        successes += 1;
      } catch (error) {
        errors += 1;
      }
    }

    let message = `Successfully deleted ${successes} subjects from the ${info.container.owner.username}/${info.container.name} container.`

    if (errors > 0) {
      message += `\n\nFailed to delete ${errors} subject(s).`
    }

    commitRemoveNotification(context, loadingNotification);
    commitAddNotification(context, {
      content: message,
      color: errors === 0 ? "success" : "warning",
    });

    return successes > 0;
  },

  async actionGetContainerExportRules(
    context: ContainerContext,
    container: IContainer
  ) {
    try {
      const response = await api.getContainerExportRules(
        container.owner.username,
        container.name,
        0,
        -1
      );

      if (response.data) {
        commitSetLContainerExportRules(context, response.data);
        commitSetLContainerExportRulesTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetLContainerExportRules(context, []);
        commitSetLContainerExportRulesTotal(context, 0);
      }

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionAddContainerExportRule(
    context: ContainerContext,
    info: { container: IContainer; rule: IContainerExportRuleCreate }
  ) {
    const loadingNotification = {
      content: "Adding export rule to container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.addContainerExportRule(
        info.container.owner.username,
        info.container.name,
        info.rule
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully added export rule to the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      commitAddLContainerExportRule(context, response.data)

      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionUpdateContainerExportRule(
    context: ContainerContext,
    info: { container: IContainer; rule: IContainerExportRule; ruleUpdate: IContainerExportRuleUpdate }
  ) {
    const loadingNotification = {
      content: "Updating export rule of container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateContainerExportRule(
        info.container.owner.username,
        info.container.name,
        info.rule.id,
        info.ruleUpdate
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully updated export rule in the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;

  },

  async actionDeleteContainerExportRule(
    context: ContainerContext,
    info: { container: IContainer; rule: IContainerExportRule }
  ) {
    const loadingNotification = {
      content: "Deleting export rule from container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.deleteContainerExportRule(
        info.container.owner.username,
        info.container.name,
        info.rule.id
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully deleted export rule from the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      commitRemoveLContainerExportRule(context, info.rule)
      commitSetLContainerExportRulesTotal(context, context.state.lContainerExportRulesTotal - 1)

      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;

  },

  async actionRequestExportRuleGeneration(
    context: ContainerContext,
    info: { container: IContainer; rule: IContainerExportRule }
  ) {
    const loadingNotification = {
      content: "Requesting export rule file generation...",
      color: "info",
      showProgress: true,
    }

    commitAddNotification(context, loadingNotification)

    try {
      await api.requestContainerExportRuleGeneration(info.container.owner.username, info.container.name, info.rule.id)

      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: `Successfully requested export rule file generation for the ${info.container.owner.username}/${info.container.name} container.\nPlease wait a few minutes for the file to be generated.`,
        color: "success",
      })
      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error)
    }
  },

  async actionDownloadPlainTxtExportRule(
    context: ContainerContext,
    info: { container: IContainer; rule: IContainerExportRule }
  ) {
    const loadingNotification = {
      content: "Requesting export rule file...",
      color: "info",
      showProgress: true,
    }

    commitAddNotification(context, loadingNotification)

    try {
      const response = await api.downloadPlainTextContainerExportRule(info.container.owner.username, info.container.name, info.rule.id)

      if (response.status !== 204) {
        downloadBlob(response.data, `c_${info.container.name}_${info.rule.permaname}.plain.txt`)

        commitRemoveNotification(context, loadingNotification)
        commitAddNotification(context, {
          content: `Successfully downloaded export rule file (${info.rule.permaname}) for the ${info.container.owner.username}/${info.container.name} container.`,
          color: "success",
        })
      } else {
        commitRemoveNotification(context, loadingNotification)
        commitAddNotification(context, {
          content: `Export rule file (${info.rule.permaname}) for the ${info.container.owner.username}/${info.container.name} container is not ready yet.`,
          color: "warning",
        })
      }
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error)
    }
  },

  async actionDownloadHostsFileExportRule(
    context: ContainerContext,
    info: { container: IContainer; rule: IContainerExportRule }
  ) {
    const loadingNotification = {
      content: "Requesting export rule file...",
      color: "info",
      showProgress: true,
    }

    commitAddNotification(context, loadingNotification)

    try {
      const response = await api.downloadHostsContainerExportRule(info.container.owner.username, info.container.name, info.rule.id)

      if (response.status !== 204) {
        downloadBlob(response.data, `c_${info.container.name}_${info.rule.permaname}.hosts.txt`)

        commitRemoveNotification(context, loadingNotification)
        commitAddNotification(context, {
          content: `Successfully downloaded export rule file (${info.rule.permaname}) for the ${info.container.owner.name}/${info.container.name} container.`,
          color: "success",
        })
      } else {
        commitRemoveNotification(context, loadingNotification)
        commitAddNotification(context, {
          content: `Export rule file (${info.rule.permaname}) for the ${info.container.owner.name}/${info.container.name} container is not ready yet.`,
          color: "warning",
        })
      }
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error)
    }
  },

  async actionDownloadCompressedHostsFileExportRule(
    context: ContainerContext,
    info: { container: IContainer; rule: IContainerExportRule }
  ) {
    const loadingNotification = {
      content: "Requesting export rule file...",
      color: "info",
      showProgress: true,
    }

    commitAddNotification(context, loadingNotification)

    try {
      const response = await api.downloadCompressedHostsContainerExportRule(info.container.owner.username, info.container.name, info.rule.id)

      if (response.status !== 204) {

        downloadBlob(response.data, `c_${info.container.name}_${info.rule.permaname}.compressed-hosts.txt`)

        commitRemoveNotification(context, loadingNotification)
        commitAddNotification(context, {
          content: `Successfully downloaded export rule file (${info.rule.permaname}) for the ${info.container.owner.username}/${info.container.name} container.`,
          color: "success",
        })
      } else {
        commitRemoveNotification(context, loadingNotification)
        commitAddNotification(context, {
          content: `Export rule file (${info.rule.permaname}) for the ${info.container.owner.username}/${info.container.name} container is not ready yet.`,
          color: "warning",
        })
      }
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error)
    }
  },

  async actionGetContainerRemoteSubjects(
    context: ContainerContext,
    container: IContainer
  ) {
    try {
      const response = await api.getContainerRemoteSubjects(
        container.owner.username,
        container.name,
        0,
        -1
      );

      if (response.data) {
        commitSetLContainerRemoteSubjects(context, response.data);
        commitSetLContainerRemoteSubjectsTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetLContainerRemoteSubjects(context, []);
        commitSetLContainerRemoteSubjectsTotal(context, 0);
      }

      return response.data
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionAddContainerRemoteSubject(
    context: ContainerContext,
    info: { container: IContainer; remoteSubject: IContainerRemoteSubjectCreate }
  ) {
    const loadingNotification = {
      content: "Adding remote source into container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.addContainerRemoteSubject(
        info.container.owner.username,
        info.container.name,
        info.remoteSubject
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully added remote source into the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionUpdateContainerRemoteSubject(
    context: ContainerContext,
    info: { container: IContainer; remoteSubject: IContainerRemoteSubject; remoteSubjectUpdate: IContainerRemoteSubjectUpdate }
  ) {
    const loadingNotification = {
      content: "Updating remote source of container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.updateContainerRemoteSubject(
        info.container.owner.username,
        info.container.name,
        info.remoteSubject.id,
        info.remoteSubjectUpdate
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully updated the selected remote source of the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionDeleteContainerRemoteSubject(
    context: ContainerContext,
    info: { container: IContainer; remoteSubject: IContainerRemoteSubject }
  ) {
    const loadingNotification = {
      content: "Deleting remote source from container...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.deleteContainerRemoteSubject(
        info.container.owner.username,
        info.container.name,
        info.remoteSubject.id
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully deleted remote source from the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      commitSetLContainerRemoteSubjectsTotal(context, context.state.lContainerRemoteSubjectsTotal - 1)
      commitRemoveLContainerRemoteSubject(context, info.remoteSubject)

      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionTestContainerRemoteSourceAxfr(
    context: ContainerContext,
    info: { container: IContainer; remoteSubject: IContainerRemoteSubject }
  ) {
    const loadingNotification = {
      content: "Testing remote source AXFR...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.testContainerRemoteSubjectAxfr(
        info.container.owner.username,
        info.container.name,
        info.remoteSubject.id
      );

      commitRemoveNotification(context, loadingNotification);

      if (response.status !== 200) {
        commitAddNotification(context, {
          content: `Failed to test remote source AXFR for the ${info.container.owner.username}/${info.container.name} container.`,
          color: "danger",
        });

        return false;
      }

      commitAddNotification(context, {
        content: `Successfully tested remote source AXFR for the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });
      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return false
  },

  async actionRequestRemoteSourceRefresh(
    context: ContainerContext,
    info: { container: IContainer; remoteSubject: IContainerRemoteSubject }
  ) {
    const loadingNotification = {
      content: "Requesting remote source refresh...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.refreshContainerRemoteSubject(
        info.container.owner.username,
        info.container.name,
        info.remoteSubject.id
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully requested remote source refresh for the ${info.container.owner.username}/${info.container.name} container.`,
        color: "success",
      });

      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return false;
  },

  async actionGetContainerAssociatedSubjects(
    context: ContainerContext, info: { container: IContainer, offset: number, limit: number }) {
    try {
      const response = await api.getContainerSubjects(
        info.container.owner.username,
        info.container.name,
        info.offset ? info.offset : 0,
        info.limit ? info.limit : 100
      );

      if (response.data) {
        commitSetLContainerAssociatedSubjects(context, response.data);
        commitSetLContainerAssociatedSubjectsTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetLContainerAssociatedSubjects(context, []);
        commitSetLContainerAssociatedSubjectsTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGetContainerIgnoredSubjects(
    context: ContainerContext, info: { container: IContainer, offset: number, limit: number }) {
    try {
      const response = await api.getContainerIgnoredSubjects(
        info.container.owner.username,
        info.container.name,
        info.offset ? info.offset : 0,
        info.limit ? info.limit : 100
      );

      if (response.data) {
        commitSetLContainerIgnoredSubjects(context, response.data);
        commitSetLContainerIgnoredSubjectsTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetLContainerIgnoredSubjects(context, []);
        commitSetLContainerIgnoredSubjectsTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGetContainerStashedSubjects(
    context: ContainerContext, info: { container: IContainer, offset: number, limit: number }) {
    try {
      const response = await api.getContainerStashedSubjects(
        info.container.owner.username,
        info.container.name,
        info.offset ? info.offset : 0,
        info.limit ? info.limit : 100
      );

      if (response.data) {
        commitSetLContainerStashedSubjects(context, response.data);
        commitSetLContainerStashedSubjectsTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetLContainerStashedSubjects(context, []);
        commitSetLContainerStashedSubjectsTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },


}


const { dispatch } = getStoreAccessors<ContainerState | any, State>("");


export const dispatchGetContainers = dispatch(actions.actionGetContainers);
export const dispatchGetContainer = dispatch(actions.actionGetContainer);
export const dispatchAddContainer = dispatch(actions.actionAddContainer);
export const dispatchUpdateContainer = dispatch(
  actions.actionUpdateContainer
);
export const dispatchDeleteContainer = dispatch(
  actions.actionDeleteContainer
);

export const dispatchOverrideContainerOwners = dispatch(
  actions.actionOverrideContainerOwners
);
export const dispatchOverrideContainerContributors = dispatch(
  actions.actionOverrideContainerContributors
);
export const dispatchOverrideContainerViewers = dispatch(
  actions.actionOverrideContainerViewers
);

export const dispatchSearchContainer = dispatch(actions.actionSearchContainer);
export const dispatchSearchAndPushContainers = dispatch(actions.actionSearchAndPushContainers);
export const dispatchSearchContainerSubjects = dispatch(actions.actionSearchContainerSubjects);
export const dispatchSearchContainerIgnoredSubjects = dispatch(actions.actionSearchContainerIgnoredSubjects);
export const dispatchSearchContainerStashedSubjects = dispatch(actions.actionSearchContainerStashedSubjects);

export const dispatchGetContainersTotal = dispatch(actions.actionGetContainersTotal);

export const dispatchAddContainerSubjects = dispatch(actions.actionAddContainerSubjects)
export const dispatchStashContainerSubjects = dispatch(actions.actionStashContainerSubjects)
export const dispatchIgnoreContainerSubjects = dispatch(actions.actionIgnoreContainerSubjects)

export const dispatchAddSingleContainerSubject = dispatch(actions.actionAddSingleContainerSubject)
export const dispatchStashSingleContainerSubject = dispatch(actions.actionStashSingleContainerSubject)
export const dispatchIgnoreSingleContainerSubject = dispatch(actions.actionIgnoreSingleContainerSubject)
export const dispatchDeleteSingleContainerSubject = dispatch(actions.actionDeleteSingleContainerSubject)

export const dispatchAddMultipleContainerSubject = dispatch(actions.actionAddMultipleContainerSubject)
export const dispatchStashMultipleContainerSubject = dispatch(actions.actionStashMultipleContainerSubject)
export const dispatchIgnoreMultipleContainerSubject = dispatch(actions.actionIgnoreMultipleContainerSubject)
export const dispatchDeleteMultipleContainerSubject = dispatch(actions.actionDeleteMultipleContainerSubject)

export const dispatchGetContainerExportRules = dispatch(actions.actionGetContainerExportRules)
export const dispatchAddContainerExportRule = dispatch(actions.actionAddContainerExportRule)
export const dispatchUpdateContainerExportRule = dispatch(actions.actionUpdateContainerExportRule)
export const dispatchDeleteContainerExportRule = dispatch(actions.actionDeleteContainerExportRule)

export const dispatchRequestExportRuleGeneration = dispatch(actions.actionRequestExportRuleGeneration)
export const dispatchDownloadPlainTxtExportRule = dispatch(actions.actionDownloadPlainTxtExportRule)
export const dispatchDownloadHostsFileExportRule = dispatch(actions.actionDownloadHostsFileExportRule)
export const dispatchDownloadCompressedHostsFileExportRule = dispatch(actions.actionDownloadCompressedHostsFileExportRule)

export const dispatchGetContainerRemoteSubjects = dispatch(actions.actionGetContainerRemoteSubjects)
export const dispatchAddContainerRemoteSubject = dispatch(actions.actionAddContainerRemoteSubject)
export const dispatchUpdateContainerRemoteSubject = dispatch(actions.actionUpdateContainerRemoteSubject)
export const dispatchDeleteContainerRemoteSubject = dispatch(actions.actionDeleteContainerRemoteSubject)
export const dispatchTestContainerRemoteSourceAxfr = dispatch(actions.actionTestContainerRemoteSourceAxfr)
export const dispatchRequestRemoteSourceRefresh = dispatch(actions.actionRequestRemoteSourceRefresh)

export const dispatchGetContainerAssociatedSubjects = dispatch(actions.actionGetContainerAssociatedSubjects)
export const dispatchGetContainerIgnoredSubjects = dispatch(actions.actionGetContainerIgnoredSubjects)
export const dispatchGetContainerStashedSubjects = dispatch(actions.actionGetContainerStashedSubjects)

export const dispatchLockSingleContainerSubjectLink = dispatch(actions.actionLockSingleContainerSubjectLink)
export const dispatchLockMultipleContainerSubjectLink = dispatch(actions.actionLockMultipleContainerSubjectLink)
export const dispatchUnlockSingleContainerSubjectLink = dispatch(actions.actionUnlockSingleContainerSubjectLink)
export const dispatchUnlockMultipleContainerSubjectLink = dispatch(actions.actionUnlockMultipleContainerSubjectLink)
