import {
  IContainer,
  IContainerCreate,
  IContainerExportRule,
  IContainerExportRuleCreate,
  IContainerExportRuleUpdate,
  IContainerRemoteSubject,
  IContainerRemoteSubjectCreate,
  IContainerRemoteSubjectUpdate,
  IContainerSearch,
  IContainerSubject,
  IContainerUpdate,
} from "@/interfaces/container";
import { ISubjectSearch, ISubjectSubmit } from "@/interfaces/aggregation/subject";

import { IAPIMessage } from "@/interfaces/message";
import { IUser } from "@/interfaces/user";
import { apiUrl } from "@/consts";
import axios from "axios";
import { getIsLoggedIn } from "@/utils";

export const containerMetadataAPI = {
  async getContainerStats() {
    if (getIsLoggedIn()) {
      return axios.head<null>(
        `${apiUrl}/v1/containers`,
      );
    }
    return axios.head<null>(
      `${apiUrl}/v1/hub/containers`
    )
  },
};


export const containerCheckAPI = {
  async checkContainerName(owner: string, containername: string) {
    if (getIsLoggedIn()) {
      return axios.head<null>(
        `${apiUrl}/v1/containers/${owner}/${containername}`,
      );
    }
    return axios.head<null>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}`,
    );
  },
};

export const containersAPI = {
  ...containerMetadataAPI,
  ...containerCheckAPI,

  async searchContainers(searchQuery: IContainerSearch, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.post<IContainer[]>(`${apiUrl}/v1/containers/search?offset=${offset}&limit=${limit}`, searchQuery)
    }
    return axios.post<IContainer[]>(`${apiUrl}/v1/hub/containers/search?offset=${offset}&limit=${limit}`, searchQuery)
  },

  async searchContainerSubjects(owner: string, containername: string, searchQuery: ISubjectSearch, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.post<IContainerSubject[]>(`${apiUrl}/v1/containers/${owner}/${containername}/subjects/associated/search?offset=${offset}&limit=${limit}`,
        searchQuery
      )
    }
    return axios.post<IContainerSubject[]>(`${apiUrl}/v1/hub/containers/${owner}/${containername}/subjects/associated/search?offset=${offset}&limit=${limit}`,
      searchQuery
    )
  },

  async searchContainerStashedSubjects(owner: string, containername: string, searchQuery: ISubjectSearch, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.post<IContainerSubject[]>(`${apiUrl}/v1/containers/${owner}/${containername}/subjects/stashes/search?offset=${offset}&limit=${limit}`,
        searchQuery
      )
    }
    return axios.post<IContainerSubject[]>(`${apiUrl}/v1/hub/containers/${owner}/${containername}/subjects/stashes/search?offset=${offset}&limit=${limit}`,
      searchQuery
    )
  },

  async searchContainerIgnoredSubjects(owner: string, containername: string, searchQuery: ISubjectSearch, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.post<IContainerSubject[]>(`${apiUrl}/v1/containers/${owner}/${containername}/subjects/ignored/search?offset=${offset}&limit=${limit}`,
        searchQuery
      )
    }
    return axios.post<IContainerSubject[]>(`${apiUrl}/v1/hub/containers/${owner}/${containername}/subjects/ignored/search?offset=${offset}&limit=${limit}`,
      searchQuery
    )
  },

  async getContainers(offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IContainer[]>(
        `${apiUrl}/v1/containers?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<IContainer[]>(
      `${apiUrl}/v1/hub/containers?offset=${offset}&limit=${limit}`,
    );
  },

  async getContainer(owner: string, containername: string) {
    if (getIsLoggedIn()) {
      return axios.get<IContainer>(
        `${apiUrl}/v1/containers/${owner}/${containername}`,
      );
    }

    return axios.get<IContainer>(`${apiUrl}/v1/hub/containers/${owner}/${containername}`);
  },

  async updateContainer(
    owner: string,
    containername: string,
    container: IContainerUpdate
  ) {
    return axios.patch<IContainer>(
      `${apiUrl}/v1/containers/${owner}/${containername}`,
      container,
    );
  },

  async deleteContainer(owner: string, containername: string) {
    return axios.delete<void>(
      `${apiUrl}/v1/containers/${owner}/${containername}`,
    );
  },

  async addContainer(container: IContainerCreate) {
    return axios.post<IContainer>(
      `${apiUrl}/v1/containers`,
      container,
    );
  },

  async overrideContainerOwners(owner: string, containername: string, owners: string[]) {
    return axios.put<IUser[]>(
      `${apiUrl}/v1/containers/${owner}/${containername}/owners`,
      owners,
    );
  },

  async overrideContainerContributors(owner: string, containername: string, contributors: string[]) {
    return axios.put<IUser[]>(
      `${apiUrl}/v1/containers/${owner}/${containername}/contributors`,
      contributors,
    );
  },

  async overrideContainerViewers(owner: string, containername: string, viewers: string[]) {
    return axios.put<IUser[]>(
      `${apiUrl}/v1/containers/${owner}/${containername}/viewers`,
      viewers,
    );
  },

  async getContainerOwners(owner: string, containername: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IUser[]>(
        `${apiUrl}/v1/containers/${owner}/${containername}/owners?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<IUser[]>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/owners?offset=${offset}&limit=${limit}`,
    );
  },

  async getContainerContributors(owner: string, containername: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IUser[]>(
        `${apiUrl}/v1/containers/${owner}/${containername}/contributors?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<IUser[]>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/contributors?offset=${offset}&limit=${limit}`,
    );
  },

  async getContainerViewers(owner: string, containername: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IUser[]>(
        `${apiUrl}/v1/containers/${owner}/${containername}/contributors?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<IUser[]>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/contributors?offset=${offset}&limit=${limit}`,
    );
  },

  async getContainerSubjects(owner: string, containername: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IContainerSubject[]>(
        `${apiUrl}/v1/containers/${owner}/${containername}/subjects/associated?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<IContainerSubject[]>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/subjects/associated?offset=${offset}&limit=${limit}`,
    );
  },

  async getContainerStashedSubjects(owner: string, containername: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IContainerSubject[]>(
        `${apiUrl}/v1/containers/${owner}/${containername}/subjects/stashes?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<IContainerSubject[]>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/subjects/stashes?offset=${offset}&limit=${limit}`,
    );
  },

  async getContainerIgnoredSubjects(owner: string, containername: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IContainerSubject[]>(
        `${apiUrl}/v1/containers/${owner}/${containername}/subjects/ignored?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<IContainerSubject[]>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/subjects/ignored?offset=${offset}&limit=${limit}`,
    );
  },

  async addContainerSubjects(owner: string, containername: string, subjects: ISubjectSubmit) {
    return axios.post<IContainerSubject[]>(
      `${apiUrl}/v1/containers/${owner}/${containername}/subjects/associated`,
      subjects,
    );
  },

  async addContainerSubject(owner: string, containername: string, subjectID: string) {
    return axios.put<void>(
      `${apiUrl}/v1/containers/${owner}/${containername}/subjects/${subjectID}/associate`,
      {},
    );
  },

  async stashContainerSubjects(owner: string, containername: string, subjects: ISubjectSubmit) {
    return axios.post<IContainerSubject[]>(
      `${apiUrl}/v1/containers/${owner}/${containername}/subjects/stashes`,
      subjects,
    );
  },

  async stashContainerSubject(owner: string, containername: string, subjectID: string) {
    return axios.put<void>(
      `${apiUrl}/v1/containers/${owner}/${containername}/subjects/${subjectID}/stash`,
      {},
    );
  },


  async ignoreContainerSubjects(owner: string, containername: string, subjects: ISubjectSubmit) {
    return axios.post<IContainerSubject[]>(
      `${apiUrl}/v1/containers/${owner}/${containername}/subjects/ignored`,
      subjects,
    );
  },

  async ignoreContainerSubject(owner: string, containername: string, subjectID: string) {
    return axios.put<void>(
      `${apiUrl}/v1/containers/${owner}/${containername}/subjects/${subjectID}/ignore`,
      {},
    );
  },

  async lockContainerSubjectLink(owner: string, containername: string, subjectID: string) {
    return axios.put<void>(
      `${apiUrl}/v1/containers/${owner}/${containername}/subjects/${subjectID}/lock-link`,
      {},
    );
  },

  async unlockContainerSubjectLink(owner: string, containername: string, subjectID: string) {
    return axios.put<void>(
      `${apiUrl}/v1/containers/${owner}/${containername}/subjects/${subjectID}/unlock-link`,
      {},
    );
  },

  async deleteContainerSubject(owner: string, containername: string, subjectID: string) {
    return axios.delete<void>(
      `${apiUrl}/v1/containers/${owner}/${containername}/subjects/${subjectID}`,
    );
  },

  async addContainerExportRule(owner: string, containername: string, exportRule: IContainerExportRuleCreate) {
    return axios.post<IContainerExportRule>(
      `${apiUrl}/v1/containers/${owner}/${containername}/export-rules/`,
      exportRule,
    );
  },

  async updateContainerExportRule(owner: string, containername: string, exportRuleID: string, exportRule: IContainerExportRuleUpdate) {
    return axios.patch<IContainerExportRule>(
      `${apiUrl}/v1/containers/${owner}/${containername}/export-rules/${exportRuleID}`,
      exportRule,
    );
  },

  async deleteContainerExportRule(owner: string, containername: string, exportRuleID: string) {
    return axios.delete<IContainerExportRule>(
      `${apiUrl}/v1/containers/${owner}/${containername}/export-rules/${exportRuleID}`,
    );
  },

  async getContainerExportRules(owner: string, containername: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IContainerExportRule[]>(
        `${apiUrl}/v1/containers/${owner}/${containername}/export-rules?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<IContainerExportRule[]>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/export-rules?offset=${offset}&limit=${limit}`,
    );
  },

  async getContainerExportRule(owner: string, containername: string, exportRuleID: string) {
    if (getIsLoggedIn()) {
      return axios.get<IContainerExportRule>(
        `${apiUrl}/v1/containers/${owner}/${containername}/export-rules/${exportRuleID}`,
      );
    }
    return axios.get<IContainerExportRule>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/export-rules/${exportRuleID}`,
    );
  },

  async requestContainerExportRuleGeneration(owner: string, containername: string, exportRuleID: string) {
    return axios.put<IAPIMessage>(
      `${apiUrl}/v1/containers/${owner}/${containername}/export-rules/${exportRuleID}/generate`,
      {},
    )
  },

  async downloadPlainTextContainerExportRule(owner: string, containername: string, exportRuleID: string) {
    if (getIsLoggedIn()) {
      return axios.get<Blob>(
        `${apiUrl}/v1/containers/${owner}/${containername}/export-rules/${exportRuleID}/plain`,
        {
          responseType: 'blob',
        }
      )
    }

    return axios.get<Blob>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/export-rules/${exportRuleID}/plain`,
      {
        responseType: 'blob',
      }
    )
  },

  async downloadHostsContainerExportRule(owner: string, containername: string, exportRuleID: string) {
    if (getIsLoggedIn()) {
      return axios.get<Blob>(
        `${apiUrl}/v1/containers/${owner}/${containername}/export-rules/${exportRuleID}/hosts`,
        {
          responseType: 'blob',
        }
      )
    }
    return axios.get<Blob>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/export-rules/${exportRuleID}/hosts`,
      {
        responseType: 'blob',
      }
    )
  },

  async downloadCompressedHostsContainerExportRule(owner: string, containername: string, exportRuleID: string) {
    if (getIsLoggedIn()) {
      return axios.get<Blob>(
        `${apiUrl}/v1/containers/${owner}/${containername}/export-rules/${exportRuleID}/compressed-hosts`,
        {
          responseType: 'blob',
        }
      )
    }
    return axios.get<Blob>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/export-rules/${exportRuleID}/compressed-hosts`,
      {
        responseType: 'blob',
      }
    )
  },

  async getContainerRemoteSubjects(owner: string, containername: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IContainerRemoteSubject[]>(
        `${apiUrl}/v1/containers/${owner}/${containername}/remote-sources?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<IContainerRemoteSubject[]>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/remote-sources?offset=${offset}&limit=${limit}`,
    );
  },

  async getContainerRemoteSubject(owner: string, containername: string, remoteSubjectID: string) {
    if (getIsLoggedIn()) {
      return axios.get<IContainerRemoteSubject>(
        `${apiUrl}/v1/containers/${owner}/${containername}/remote-sources/${remoteSubjectID}`,
      );
    }
    return axios.get<IContainerRemoteSubject>(
      `${apiUrl}/v1/hub/containers/${owner}/${containername}/remote-sources/${remoteSubjectID}`,
    );
  },

  async deleteContainerRemoteSubject(owner: string, containername: string, remoteSubjectID: string) {
    return axios.delete<IContainerRemoteSubject>(
      `${apiUrl}/v1/containers/${owner}/${containername}/remote-sources/${remoteSubjectID}`,
    );
  },

  async updateContainerRemoteSubject(owner: string, containername: string, remoteSubjectID: string, remoteSubject: IContainerRemoteSubjectUpdate) {
    return axios.patch<IContainerRemoteSubject>(
      `${apiUrl}/v1/containers/${owner}/${containername}/remote-sources/${remoteSubjectID}`,
      remoteSubject,
    );
  },

  async testContainerRemoteSubjectAxfr(owner: string, containername: string, remoteSubjectID: string) {
    return axios.put<IAPIMessage>(
      `${apiUrl}/v1/containers/${owner}/${containername}/remote-sources/${remoteSubjectID}/test-axfr`,
      {},
    );
  },

  async refreshContainerRemoteSubject(owner: string, containername: string, remoteSubjectID: string) {
    return axios.put<IAPIMessage>(
      `${apiUrl}/v1/containers/${owner}/${containername}/remote-sources/${remoteSubjectID}/refresh`,
      {},
    );
  },

  async addContainerRemoteSubject(owner: string, containername: string, remoteSubject: IContainerRemoteSubjectCreate) {
    return axios.post<IContainerRemoteSubject>(
      `${apiUrl}/v1/containers/${owner}/${containername}/remote-sources`,
      remoteSubject,
    );
  },

}

export default containersAPI;